import React from 'react'
import { graphql } from 'gatsby'
import CaseStudyLayout from '../../../components/caseStudyLayout'
import * as styles from './styles.module.css'
import { Helmet } from 'react-helmet'

import heroTop from './img/hero-top.png'
import screensV4 from './img/screens-v4.png'
import devices from './img/devices.svg'
import heroBottom from './img/hero-bottom.svg'

const Breezing = ({ data }) => {
  return (
    <>
      <CaseStudyLayout {...data}>
        <Helmet bodyAttributes={{ class: styles.breezingBody }}>
          <link
            href="https://fonts.googleapis.com/css?family=Oxygen:300,400,700&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <div className={styles.breezing}>
          <span className="overlay"></span>
          <section className={`${styles.container} ${styles.titleContainer}`}>
            <div className={`${styles.row}`}>
              <div className={`${styles.col12}`}>
                <h2 className={`${styles.title} ${styles.noSpace}`}>
                  Breezing Pro<span>Precise metabolic assessment</span>
                </h2>
              </div>
            </div>
          </section>
          <section
            className={`${styles.hero}`}
            style={{
              backgroundImage: `url('${heroTop}')`,
            }}
          >
            <div className={`${styles.container}`}>
              <img src={devices} alt="" />
            </div>
          </section>
          <section
            className={`${styles.heroBottom}`}
            style={{
              backgroundImage: `url('${heroBottom}')`,
              marginTop: '-2px',
            }}
          >
            <section
              className={`${styles.textContainer} ${styles.spacer} ${styles.padTop}`}
            >
              <div className={`${styles.flex} ${styles.container}`}>
                <div className={`${styles.row}`}>
                  <div className={`${styles.col3} ${styles.textNav}`}>
                    <div className={`${styles.num}`}>01</div>
                    <div className={`${styles.navTitle}`}>Background</div>
                  </div>

                  <div className={`${styles.col9} ${styles.textContent}`}>
                    <p>
                      A passionate team of researchers, scientists, and
                      engineers, Breezing is a technology transfer project out
                      of Arizona State University. They’ve developed a
                      revolutionary medical device that can measure a patient’s
                      metabolism REE (Resting Energy Expenditure) purely from
                      their breath, telling patients and clinicians how many
                      calories they burn.
                    </p>
                    <p>
                      This reading is extremely accurate and provides much more
                      valuable data to clinicians than traditional REE
                      calculators which give an estimate based on patients’ age,
                      height, weight, and activity level. Instead, Breezing
                      provides data specific to each patient that uses it,
                      allowing clinicians to give better and more meaningful
                      care.
                    </p>
                    <p>
                      While Breezing engineered the mask hardware itself, they
                      turned to Synapse to build a HIPAA-compliant, Bluetooth
                      Low Energy-capable companion app to communicate with the
                      device, initiate tests, and store results in a secure
                      environment.
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </section>
          <section className={`${styles.textContainer}`}>
            <div className={`${styles.flex} ${styles.container}`}>
              <div className={`${styles.row}`}>
                <div className={`${styles.col3} ${styles.textNav}`}>
                  <div className={`${styles.num}`}>02</div>
                  <div className={`${styles.navTitle}`}>The Challenge</div>
                </div>
                <div className={`${styles.col9} ${styles.textContent}`}>
                  <p>
                    This project presented a few initial challenges to overcome:
                    accurately replicating the measurement logic, interfacing
                    with an external hardware component over BLE, creating an
                    app that was HIPAA / FDA compliant, and recreating the user
                    experience to be tailored towards the time-crunched lives of
                    the clinicians that would be including Breezing into their
                    daily practices.
                  </p>
                  <p>
                    This project also presented new challenges along the way:
                    creating a separate version of the app which stored data
                    locally to a device for partners that did not want cloud
                    storage, setting up private distribution for the local
                    storage app, and facilitating over-the-air firmware updates
                    for the hardware.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className={`${styles.mockupContainer} ${styles.spacer}`}>
            <img src={screensV4} alt="" />
          </section>
          <section className={`${styles.containerBackground} ${styles.bgGrey}`}>
            <div className={`${styles.textContainer}`}>
              <div className={`${styles.flex} ${styles.container}`}>
                <div className={`${styles.row}`}>
                  <div className={`${styles.col3} ${styles.textNav}`}>
                    <div className={`${styles.num}`}>03</div>
                    <div className={`${styles.navTitle}`}>The Solution</div>
                  </div>
                  <div className={`${styles.col9} ${styles.textContent}`}>
                    <h4 className={`${styles.subtitle} ${styles.noPad}`}>
                      Guided UX
                    </h4>
                    <p>
                      Synapse started the project by designing the app with the
                      end-user in mind. The app needed to be easy-to-use and
                      allow clinicians to quickly fire off tests without the
                      confusion of option overload. Breezing was consulted
                      throughout design to bring the idea to life.
                    </p>

                    <h4 className={`${styles.subtitle} ${styles.noPad}`}>
                      Measurement accuracy
                    </h4>

                    <p>
                      Synapse worked directly with the Breezing team to ensure
                      the measurement data interpolation logic was being
                      executed correctly and produced accurate results.
                    </p>

                    <h4 className={`${styles.subtitle} ${styles.noPad}`}>
                      External hardware BLE
                    </h4>

                    <p>
                      Synapse utilized our talented development resources to
                      solve some complex external hardware Bluetooth
                      communications problems to ensure our goals were being met
                      in a timely fashion.
                    </p>

                    <h4 className={`${styles.subtitle} ${styles.noPad}`}>
                      Compliance
                    </h4>
                    <p>
                      While we’ve worked on a number of HIPAA-compliant apps, we
                      also consulted with FDA compliance experts to ensure all
                      the i’s were dotted and t’s were crossed.
                    </p>

                    <h4 className={`${styles.subtitle} ${styles.noPad}`}>
                      Firmware updates
                    </h4>
                    <p>
                      A major feature need was the ability for the app to
                      execute a firmware update on a connected device.
                      Problematically, previous-generation devices did not allow
                      for this functionality and the Breezing team was working
                      on the new device feature in parallel to app development.
                      In order to avoid a “handoff” from Breezing to Synapse, we
                      developed the feature against a Texas Instrument test
                      board. This allowed us to build the feature app-side
                      before the capability was available in the devices
                      themselves, which cut down the deliverable runway
                      considerably.
                    </p>
                    <h4 className={`${styles.subtitle} ${styles.noPad}`}>
                      Offline mode / Private Distro
                    </h4>
                    <p>
                      Midway through development of the initial app project, we
                      were asked to create a version of the app that did not
                      store data in the cloud. This was quite a shift in
                      essential functionality but we were able to make this
                      change in a short amount of time, in a scalable manner.
                      Our approach made a molehill out of a mountain.
                    </p>
                    <p>
                      In order to distribute this app privately (not seen in the
                      public app store), we utilized the Apple Business Manager
                      toolkit which allowed us to distribute redemption codes to
                      targeted parties which downloaded the private app directly
                      onto their iOS devices.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className={`${styles.containerBackground} ${styles.bgGrey}`}>
            <div className={`${styles.textContainer}`}>
              <div className={`${styles.flex} ${styles.container}`}>
                <div className={`${styles.row}`}>
                  <div className={`${styles.col3} ${styles.textNav}`}>
                    <div className={`${styles.num}`}>04</div>
                    <div className={`${styles.navTitle}`}>The Result</div>
                  </div>
                  <div className={`${styles.col9} ${styles.textContent}`}>
                    <p>
                      In the end, we shipped a sleek native app for Breezing on
                      Android and iOS that delivered the functionality and
                      capabilities they desired, and then some.
                    </p>
                    <p>
                      During development, the app was presented at different
                      events and conferences and was received with positive
                      reviews by the user-base for which the app was intended.
                    </p>
                    <p>
                      Our hope is that the Breezing measurement device and our
                      accompanying app significantly alter people’s health
                      journey, empowered with the data it provides.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </CaseStudyLayout>
    </>
  )
}

export default Breezing

export const query = graphql`
  query breezingCaseStudyQuery {
    caseStudy: contentfulCaseStudy(slug: { eq: "breezing" }) {
      seoTitle
      slug
      title
      excerpt
    }
    caseStudyPage: contentfulPage(name: { eq: "page.caseStudy" }) {
      ...pageFields
    }
    workPage: contentfulPage(name: { eq: "page.work" }) {
      workPageSlug: slug
    }
  }
`
